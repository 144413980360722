import {createRef, useContext, useEffect, useState} from "react";
import {DrupalMedia, DrupalTaxonomyTerm} from "next-drupal";
import {useRouter} from "next/router";
import {Button} from "../atoms/button";
import {MediaImage} from "../templates/media/media--image";
import {HERO_IMAGE_SIZES, HERO_MAX_WIDTH, PlaceHolderHeroImage} from "./hero";
import {HERO_IMAGE_STYLE} from "@/types/image-styles";
import {FrostedGlassContainer} from "../atoms/gradient-container";
import {Dictionary, ProductFinderProduct} from "@/types/hygiena-types";
import classNames from "classnames";
import {DictionaryContext} from "@/context/dictionary-context";

/**
 * Generates a random background.
 */
function randomProductTypeBackground(productTypes: DrupalTaxonomyTerm[]) {
  if (!productTypes?.length) return undefined;
  // Get only the backgrounds from terms that have the product finder checkbox enabled, and
  // their children.
  let productTypesWithBG = productTypes.filter(item => {
    return item?.field_product_type_image && (item?.field_product_type_product_find)
  });
  let ids: Record<string, boolean> = {};
  for (const item of productTypesWithBG) ids[item.id] = true;
  productTypesWithBG = productTypesWithBG.concat(productTypes.filter(item => ids[item?.parent?.[0]?.id]));

  if (productTypesWithBG?.length) {
    const offset = Math.floor(Math.random() * productTypesWithBG.length)
    return productTypesWithBG?.[offset]?.field_product_type_image;
  }
  return undefined;
}

/**
 * The product category solutions selector.
 *
 * @param productTypes
 *   The product categories.
 * @constructor
 */
function ProductCategorySolutions({productTypes, setBackground}: {productTypes: DrupalTaxonomyTerm[], setBackground: Function}) {
  // Top level test subtypes and categories:
  // Category -> Group -> Type (optional)
  let currentCategory;
  let currentGroup;
  let currentType;
  let currentSubtype;
  const t = useContext<Dictionary>(DictionaryContext);
  const categories = productTypes.filter(term => !term?.parent[0]?.id);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const groups = productTypes.filter(term => (term?.parent && String(term?.parent?.[0]?.id) === selectedCategory));
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const types = productTypes.filter(term => term?.parent && String(term?.parent?.[0]?.id) === selectedGroup);
  const [selectedType, setSelectedType] = useState<string>();
  const subtypes = productTypes.filter(term => term?.parent && String(term?.parent?.[0]?.id) === selectedType);
  const [selectedSubtype, setSelectedSubtype] = useState<string>();

  /**
   * Get the current testing target.
   */
  function getCurrentTerm(id, terms): DrupalTaxonomyTerm|null {
    for (const item of terms) {
      if (String(item.id) === id) {
        return item;
      }
    }
    return null;
  }
  currentCategory = getCurrentTerm(selectedCategory, productTypes);
  currentGroup = getCurrentTerm(selectedGroup, productTypes);
  currentType = getCurrentTerm(selectedType, productTypes);
  currentSubtype = getCurrentTerm(selectedSubtype, productTypes);

  /**
   * Updates the selected group and resets the selected subtype (if available).
   *
   * @param group
   */
  function updateSelectedGroup(group?: string) {
    setSelectedGroup(group);
    updateSelectedType(undefined);
  }

  /**
   * Updates the selected category and resets the selected subtype (if available).
   *
   * @param category
   */
  function updateSelectedCategory(category?: string) {
    setSelectedCategory(category);
    updateSelectedGroup(undefined);
  }

  /**
   * Updates the selected type.
   *
   * @param type
   */
  function updateSelectedType (type?: string) {
    const target = productTypes.filter(term => term?.parent && term?.parent[0]?.id === String(type));
    setSelectedSubtype(target[0]?.id);
    setSelectedType(type);
  }

  /**
   * Returns a label displayed in the button.
   *
   * @return string
   */
  function getSolutionsLabel(): string {
    return getSelectedType()?.name ?? "";
  }

  /**
   * Gets the path of the currently selected term.
   */
  function getAlias() {
    let alias = '';
    // If we have a test target, use that.
    if (subtypes?.length && currentSubtype?.path?.alias) {
      alias = currentSubtype.path.alias;
    }
    // If we have a group subtype, use that.
    else if (types?.length && getSelectedType()?.path?.alias) {
      alias = getSelectedType()?.path?.alias ?? "";
    }
    // Otherwise use the Group.
    else if (currentGroup?.path?.alias) {
      alias = currentGroup.path.alias;
    }
    return alias;
  }

  useEffect(() => {
    setBackground(randomProductTypeBackground(productTypes ?? []));
  }, []);

  /**
   * Gets the background image of the most relevant term.
   */
  function getBackground() {
    if (currentSubtype?.field_product_type_image) return currentSubtype.field_product_type_image;
    else if (currentType?.field_product_type_image) return currentType.field_product_type_image;
    else if (currentGroup?.field_product_type_image) return currentGroup.field_product_type_image;
    else if (currentCategory?.field_product_type_image) return currentCategory.field_product_type_image;
    return randomProductTypeBackground(productTypes ?? []);
  }

  function getLabel() {
    // @todo localize.
    if (!currentCategory) return t.product_finder.please_select_class;
    if (!currentGroup || !types.length) return t.product_finder.please_select_category;
    if (!currentSubtype && !subtypes?.length) return t.product_finder.please_select_group;
    return t.product_finder.please_select_type;
  }

  function getSelectedType(): DrupalTaxonomyTerm|undefined {
    if (selectedSubtype && subtypes.length) return subtypes.filter(item => String(item?.id) === selectedSubtype)?.[0] ?? undefined;
    if (selectedType && types.length && !subtypes.length) return types.filter(item => String(item?.id) === selectedType)?.[0] ?? undefined;
    if (types?.length === 1) return types[0];
    if (currentGroup && !types.length) return currentGroup;
  }

  const currentAlias = getAlias();

  try {
    if (currentCategory) {
      const background = getBackground();
      setBackground(background ?? undefined);
    }
  }
  catch (error) {}

  // @todo localize component.
  return (
    <div className="animate-fade-in-slow flex flex-col flex-grow">
      <div>{getLabel()}</div>
      <div className="flex flex-col mb-12 py-2">
        <div className="flex">
          <label className="w-2/5 py-2">
            {t.product_finder.class}:
          </label>
          <select id={"search-solutions--category"} className="w-3/5 py-2 border-b text-black font-semibold bg-transparent" onChange={(e) => updateSelectedCategory(e.target.value)}>
            <option value={""}>{t.product_finder.select_class}</option>
            {categories.map(term => (
              <option value={term.id} key={term.id}>{term.name}</option>
            ))}
          </select>
        </div>
        <div className={classNames(
          "flex transition-opacity duration-300",
          {"opacity-0": !groups?.length, "opacity-1": groups?.length}
        )}>
          <label className="w-2/5 py-2">
            {currentCategory?.field_product_type_child_label ? (
              currentCategory.field_product_type_child_label
            ): (t.product_finder.product_category)}:
          </label>
          <select id={"search-solutions--group"} disabled={!groups?.length} className="w-3/5 py-2 border-b text-black font-semibold bg-transparent" onChange={(e) => updateSelectedGroup(e.target.value)}>
            <option value={""}>
              {t.all.select} {currentCategory?.field_product_type_child_label ? (
              currentCategory.field_product_type_child_label
            ): (t.product_finder.product_category)}
            </option>
            {groups?.length ? groups.map(term => <option value={term.id} key={term.id}>{term.name}</option>): <></>}
          </select>
        </div>
        <div className={classNames(
          "flex transition-opacity duration-300",
          {"opacity-0": !types?.length, "opacity-1": types?.length}
        )}>
          <label className="w-2/5 py-2">
            {currentGroup?.field_product_type_child_label ? (
              currentGroup.field_product_type_child_label
            ): (t.product_finder.product_group)}:
          </label>
          <select id={"search-solutions--type"} disabled={!types?.length}
                  value={types?.length === 1 ? types?.[0]?.id : selectedType}
                  className="w-3/5 py-2 border-b text-black font-semibold bg-transparent" onChange={(e) => updateSelectedType(e.target.value)}>
            <option value={""}>
              {t.all.select} {currentGroup?.field_product_type_child_label ? (currentGroup.field_product_type_child_label): (t.product_finder.product_group)}
            </option>
            {types?.length ? types.map(term => (<option value={term.id} key={term.id}>{term.name}</option>)): <></>}
          </select>
        </div>
        {subtypes.length ? (
          <div className="flex">
            <label className="w-2/5 py-2">
              {currentType?.field_product_type_child_label ? (
                currentType.field_product_type_child_label
              ): (t.product_finder.product_subtype)}:
            </label>
            <select id={"search-solutions--target"}
                    className="w-3/5 py-2 border-b text-black font-semibold bg-transparent"
                    onChange={(e) => setSelectedSubtype(e.target.value)}>
              <option value={""}>
                {t.all.select} {currentGroup?.field_product_type_child_label ? (currentGroup.field_product_type_child_label) : (t.product_finder.product_subtype)}
              </option>
              {subtypes.map(term => (
                <option value={term.id} key={term.id}>{term.name}</option>
              ))}
            </select>
          </div>
        ) : <></>}
      </div>
      {getSelectedType() && currentAlias && (
        <Button href={currentAlias} callback={() => {
        }} containerClassName="block w-full animate-fade-in-slow" className="flex flex-col bg-primary text-white px-6 py-2 rounded w-full btn-search-solutions-category">
          {t.product_finder.see_solutions} {getSolutionsLabel()}
        </Button>
      )}
    </div>
  );
}

/**
 * The industry segment selector.
 *
 * @param industrySegments
 *   The industry segments.
 * @param setBackground
 *   The set background callback.
 * @constructor
 */
function IndustrySegmentSolutions({industrySegments, setBackground}: {industrySegments: DrupalTaxonomyTerm[], setBackground: Function}) {
  // Top level test subtypes and categories:
  // Category -> Group -> Type (optional)
  let currentIndustry;
  let currentSegment;
  const t = useContext<Dictionary>(DictionaryContext);
  const industries = industrySegments.filter(term => term?.parent?.[0]?.id === "virtual");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const segments = industrySegments.filter(term => term?.parent && String(term?.parent[0]?.id) === selectedIndustry);
  const [selectedSegment, setSelectedSegment] = useState("");

  /**
   * Get the current testing target.
   */
  function getCurrentTerm(id, terms): DrupalTaxonomyTerm|null {
    for (const item of terms) {
      if (String(item.id) === id) {
        return item;
      }
    }
    return null;
  }

  currentIndustry = getCurrentTerm(selectedIndustry, industrySegments);
  currentSegment = getCurrentTerm(selectedSegment, industrySegments);

  /**
   * Updates the selected group and resets the selected subtype (if available).
   *
   * @param industry
   */
  function updateSelectedIndustry(industry?: string) {
    const segments = industrySegments.filter(term => String(term?.parent && term?.parent[0]?.id) === industry);
    setSelectedIndustry(industry ?? "");
    updateSelectedSegment("");
  }

  function getLabel() {
    // @todo localize.
    if (!selectedIndustry || ! segments?.length) return t.product_finder.please_select_industry;
    return t.product_finder.please_select_segment;
  }

  /**
   * Updates the selected type.
   *
   * @param segment
   */
  function updateSelectedSegment(segment?: string) {
    const segments = industrySegments.filter(term => String(term?.parent && term?.parent[0]?.id) === segment);
    setSelectedSegment(segment ?? "");
  }

  /**
   * Gets the path of the currently selected term.
   */
  function getAlias() {
    return currentSegment?.path?.alias ?? currentIndustry?.path?.alias ?? "";
  }

  /**
   * Gets the background image of the most relevant term.
   */
  function getBackground() {
    if (currentSegment?.field_industry_segment_link_to) {
      const industryLinkTo = industrySegments.filter(item => item?.id === currentSegment?.field_industry_segment_link_to);
      if (industryLinkTo?.[0]?.field_industry_hero_image) return industryLinkTo[0].field_industry_hero_image;
    }
    if (currentSegment?.field_industry_hero_image) return currentSegment.field_industry_hero_image;
    else if (currentIndustry?.field_industry_hero_image) return currentIndustry.field_industry_hero_image;
    return null;
  }

  function getSolutionsLabel() {
    if (currentSegment?.name) return currentSegment.name;
    else if (currentIndustry?.name) return currentIndustry.name;
    return "";
  }

  const currentAlias = getAlias();

  try {
    setBackground(getBackground());
  }
  catch (error) {
    setBackground(undefined);
  }

  return (
    <div className="animate-fade-in-slow flex flex-col flex-grow">
      <div>{getLabel()}</div>
      <div className="flex flex-col mb-12 py-2">
        <div className="flex">
          <label className="w-2/5 py-2">{t.product_finder.industry}:</label>
          <select id={"search-solutions--industry"} className="w-3/5 py-2 border-b text-black font-semibold bg-transparent" onChange={(e) => updateSelectedIndustry(e.target.value)}>
            <option value={""}>{t.product_finder.select_industry}</option>
            {industries.map(term => (
              <option value={term.id} key={term.id}>{term.name}</option>
            ))}
          </select>
        </div>
        <div className={classNames(
          "flex transition-opacity duration-300",
          {"opacity-0 pointer-events-none": !segments?.length, "opacity-1": segments?.length}
        )}>
          <label className="w-2/5 py-2">{t.product_finder.segment}:</label>
          <select id={"search-solutions--segment"} className="w-3/5 py-2 border-b text-black font-semibold bg-transparent" onChange={(e) => setSelectedSegment(e.target.value)}>
            <option value={""}>{t.product_finder.select_segment}</option>
            {segments?.length ? segments.map(term => (
              <option value={term.id} key={term.id}>{term.name}</option>
            )): <></>}
          </select>
        </div>
      </div>
      {currentAlias && (
        <Button href={currentAlias} callback={() => {}} containerClassName="block w-full" className="flex flex-col bg-primary text-white px-6 py-2 rounded w-full btn-search-solutions-industry">
          {t.product_finder.see_solutions} {getSolutionsLabel()}
        </Button>
      )}
    </div>
  );
}

/**
 * The brand selector.
 *
 * @param industrySegments
 *   The industry segments.
 * @param setBackground
 *   The set background callback.
 * @param products
 *   The products.
 * @constructor
 */
function BrandSolutions({setBackground, products, productTypes, brandMap}: {
  setBackground: Function,
  products: ProductFinderProduct[],
  brandMap: {[id: number]: string}
  productTypes: DrupalTaxonomyTerm[]
}) {
  // Top level test subtypes and categories:
  // Category -> Group -> Type (optional)
  const t = useContext<Dictionary>(DictionaryContext);
  const [ready, setReady] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const brands = getBrands();
  const categoryNames: {[key: number]: string} = {};
  productTypes.map(term => {
    if (!categoryNames?.[term.id]) categoryNames[term.id] = term.name;
  })
  const categories = getCategories();
  const categoryProducts = getCategoryProducts();
  const currentProduct = products.filter(item => String(item?.id) === selectedProduct)?.[0] ?? null;
  const currentAlias = getAlias();


  useEffect(() => {
    setBackground(randomProductTypeBackground(productTypes ?? []));
  }, []);

  /**
   * Returns all categories and brands from the available products.
   */
  function getBrands() {
    const brandsAdded = {};
    const brands = [] as any;
    for (const item of products) {
      if (item?.brand?.id && !brandsAdded?.[item.brand.id]) {
        const brandName = brandMap?.[item.brand.id];
        if (brandName) {
          brandsAdded[item.brand.id] = 1;
          brands.push({id: item.brand.id, name: brandName});
        }
      }
    }
    return brands.sort((a, b) => a?.name.localeCompare(b?.name));
  }

  function getLabel() {
    // @todo localize.
    if (!selectedBrand) return t.product_finder.please_select_brand;
    if (!selectedCategory) return t.product_finder.please_select_category;
    return t.product_finder.please_select_product;
  }

  /**
   * Returns all categories from products matching the currently selected brand.
   */
  function getCategories() {
    const categoriesAdded = {};
    const categories = [] as any;
    for (const item of products) {
      if (selectedBrand === String(item?.brand?.id) && item?.category?.id && !categoriesAdded[item.category.id]) {
        categoriesAdded[item.category.id] = item.category;
        categories.push({id: item.category.id, name: categoryNames?.[item.category.id]});
      }
      else if (selectedBrand === String(item?.brand?.id) && item?.sub_category?.id && !categoriesAdded[item.sub_category.id]) {
        const category = productTypes.filter(category => category?.id === item.sub_category.id)?.[0];
        if (category) {
          categoriesAdded[category.id] = category;
          categories.push({id: category.id, name: categoryNames?.[category.id]});
        }
      }
    }
    return categories.sort((a, b) => a?.name?.localeCompare(b?.name));
  }

  /**
   * Filters products with the currently selected brand and category.
   */
  function getCategoryProducts() {
    let category = selectedCategory;
    if (!selectedCategory && categories?.length === 1) category = categories[0]?.id ?? "";
    const filtered: ProductFinderProduct[] = products.filter(item => {
      return String(item?.brand?.id) === selectedBrand && (String(item?.category?.id) === String(category) || String(item?.sub_category?.id) === category)
    })
    return filtered.sort((a, b) => a?.title.localeCompare(b?.title));
  }

  /**
   * Get the current testing target.
   */
  function getCurrentTerm(id, terms): DrupalTaxonomyTerm|null {
    for (const item of terms) {
      if (String(item.id) === id) {
        return item;
      }
    }
    return null;
  }

  function updateSelectedBrand(id?: string) {
    setSelectedBrand(id ?? "");
    setSelectedCategory("");
    setSelectedProduct("");
  }

  function updateSelectedCategory(id?: string) {
    setSelectedCategory(id ?? "");
    setSelectedProduct("");
  }

  /**
   * Gets the path of the currently selected product.
   */
  function getAlias() {
    if (categoryProducts?.length === 1) return categoryProducts[0]?.path?.alias ?? "";
    return currentProduct?.path?.alias ?? "";
  }

  /**
   * Gets the background image of the most relevant term.
   */
  function getBackground() {
    const term = getCurrentTerm(selectedCategory, categories);
    const originalTerm = productTypes?.filter(item => item?.id === term?.id);
    return originalTerm?.[0]?.field_product_type_image ?? null;
  }

  try {
    if (selectedCategory) {
      setBackground(getBackground() ?? undefined);
    }
  }
  catch (error) {}

  return (
    <div className="animate-fade-in-slow flex flex-col flex-grow">
      <div>{getLabel()}</div>
      <div className="flex flex-col mb-12 py-2">
        <div className="flex">
          <label className="w-2/5 py-2" htmlFor="search-solutions--brand">{t.product_finder.brand}:</label>
          <select id={"search-solutions--brand"}
                  value={brands?.length === 1 ? brands?.[0]?.id : selectedBrand}
                  className="w-3/5 py-2 border-b text-black font-semibold bg-transparent" onChange={(e) => updateSelectedBrand(e.target.value)}>
            <option value="">{t.product_finder.select_brand}</option>
            {brands.map(term => (
              <option value={term.id} key={term.id}>{term.name}</option>
            ))}
          </select>
        </div>
        <div className={classNames(
          "flex transition-opacity duration-300",
          {"opacity-0 pointer-events-none": !categories?.length, "opacity-1": categories?.length}
        )}>
          <label className="w-2/5 py-2" htmlFor="search-solutions--segment">{t.product_finder.category}:</label>
          <select id={"search-solutions--segment"}
                  value={categories?.length === 1 ? categories?.[0]?.id : selectedCategory}
                  className="w-3/5 py-2 border-b text-black font-semibold bg-transparent" onChange={(e) => updateSelectedCategory(e.target.value)}>
            <option value="">{t.product_finder.select_category}</option>
            {categories?.length ? categories.map(term => (
              <option value={term.id} key={term.id}>{categoryNames?.[term.id] ? categoryNames[term.id] : ""}</option>
            )): <></>}
          </select>
        </div>
        <div className={classNames(
          "flex transition-opacity duration-300",
          {"opacity-0 pointer-events-none": !categoryProducts?.length, "opacity-1": categoryProducts?.length}
        )}>
          <label className="w-2/5 py-2" htmlFor="search-solutions--product">{t.product_finder.product}:</label>
          <select id={"search-solutions--product"} className="w-3/5 py-2 border-b text-black font-semibold bg-transparent" onChange={(e) => setSelectedProduct(e.target.value)}>
            <option value="">{t.product_finder.select_product}</option>
            {categoryProducts?.length ? categoryProducts.map(product => (
              <option value={product?.id} key={product?.id} selected={product.id === selectedProduct || categoryProducts?.length === 1}>{product.title}</option>
            )): <></>}
          </select>
        </div>
      </div>
      {currentAlias && (
        <Button href={currentAlias} callback={() => {}} containerClassName="block w-full" className="flex flex-col bg-primary text-white px-6 py-2 rounded w-full btn-search-solutions-industry">
          {t.product_finder.view_product_details}
        </Button>
      )}
    </div>
  );
}

/**
 * @todo break this out into cleaner sub-components and clean up.
 */
export function WidgetSearchSolutions ({productTypes, industrySegments, products, brands, fullHeight}: {
  productTypes: DrupalTaxonomyTerm[],
  industrySegments: DrupalTaxonomyTerm[],
  brands: {[id: number]: string},
  products: ProductFinderProduct[],
  fullHeight?: boolean,
}): JSX.Element {
  const router = useRouter();
  const ref = createRef<HTMLDivElement>();
  const t = useContext<Dictionary>(DictionaryContext);
  const [widgetType, setWidgetType] = useState<string>("Brand");
  const [background, setBackground] = useState<DrupalMedia>();

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!background) {
      setBackground(randomProductTypeBackground(productTypes ?? []));
    }
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const path = router?.asPath?.split("#")?.[1] ?? "";
    if (path === "product-finder" && ref?.current) {
      ref.current.scrollIntoView({behavior: 'smooth'})
    }
  }, []);

  return (
    <div className={classNames("w-full relative scroll-m-20 flex justify-center align-middle flex-col",
      "max-md:py-8",
      {
        "md:h-[90vh]": fullHeight,
        "md:min-h-[650px]": !fullHeight,
      }
    )} id="product-finder" ref={ref}>
      {background ? (
        <MediaImage
          loading="lazy"
          media={background}
          imageStyle={HERO_IMAGE_STYLE}
          key={`product-finder--${background.id}`}
          sizes={HERO_IMAGE_SIZES}
          className={"absolute inset-0 animate-fade-in-slow"} style={{objectFit: "cover", height: "100%", width: "100%", maxWidth: HERO_MAX_WIDTH, margin: "auto"}} />
      ): <PlaceHolderHeroImage className={"animate-fade-in-slow"} /> }
      <div className="container mx-auto md:px-6 2xl:px-0">
        <FrostedGlassContainer className="mx-4 lg:mx-0 lg:w-1/2 p-12 lg:min-h-[450px] flex flex-col max-md:p-8">
          <h2 className="mb-6">{t.product_finder.search_solutions}</h2>
          <form onSubmit={(event) => event.preventDefault()} className="flex flex-col flex-grow">
            <div className="flex gap-6 mb-6">
              {/* @todo remove duplication below, iterate and populate radios. */}
              <div>
                <label htmlFor={"search-solutions--choose--brand"} className="cursor-pointer">
                  <input defaultChecked={true} onClick={() => setWidgetType("Brand")} className="mr-1 accent-primary" type={"radio"} value={"Brands"} id={"search-solutions--choose--brand"} name={"solutions"}/>
                  {t.product_finder.brand}
                </label>
              </div>
              <div>
                <label htmlFor={"search-solutions--choose--product-category"} className="cursor-pointer">
                  <input onClick={() => setWidgetType("Product Category")} className="mr-1 accent-primary" type={"radio"} value={"Product Category"} id={"search-solutions--choose--product-category"} name={"solutions"}/>
                  {t.product_finder.products}
                </label>
              </div>
              <div>
                <label htmlFor={"search-solutions--choose--industries"} className="cursor-pointer">
                  <input onClick={() => setWidgetType("Industries")} className="mr-1 accent-primary" type={"radio"} value={"Industries"} id={"search-solutions--choose--industries"} name={"solutions"}/>
                  {t.product_finder.industries}
                </label>
              </div>
            </div>
            {widgetType === "Product Category" && (
              <ProductCategorySolutions productTypes={productTypes} setBackground={setBackground} />
            )}
            {widgetType === "Industries" && (
              <IndustrySegmentSolutions industrySegments={industrySegments} setBackground={setBackground} />
            )}
            {widgetType === "Brand" && (
              <BrandSolutions setBackground={setBackground} products={products} productTypes={productTypes} brandMap={brands} />
            )}
          </form>
        </FrostedGlassContainer>
      </div>
    </div>
  );
}