import {DrupalParagraph} from "next-drupal";
import {WidgetSearchSolutions} from "../../organisms/widget--search-solutions";

/**
 * The product finder paragraph.
 *
 * @param paragraph
 * @constructor
 */
export function ParagraphProductFinder ({paragraph}: {paragraph: DrupalParagraph}) {
  return (
    <WidgetSearchSolutions productTypes={paragraph?.productCategories ?? []}
                           brands={paragraph?.productFinderBrands ?? []}
                           industrySegments={paragraph?.industrySegments ?? []}
                           products={paragraph?.productFinderProducts ?? []}
                           fullHeight={paragraph?.field_product_finder_full_height}/>
  )
}